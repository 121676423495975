<template>
  <div class="">
    <PrivacyForm ref="form" action="Update" v-model="formData" @formSubmitted="updateStep" />
  </div>
</template>
  
  <script>
import PolicyService from "@/services/PolicyService";
import PrivacyForm from "@/views/components/privacy/PrivacyForm";
export default {
  name: "UpdatePolicy",
  components: {
    PrivacyForm
  },
  data() {
    return {
      policyService: new PolicyService(),
      type: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateStep(e) {
      this.policyService.update(this.formData._id, e).then(res => {
        this.$router.go(-1);
      }).catch(err => {
        console.log(err);
       }).finally(() => {
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getPolicy() {
      this.policyService.show(this.$route.params.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getPolicy()
  }
}
</script>
  
  <style scoped>
  </style>
  