<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref="chatForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="privacyInfo" tag="form">
          <b-row>
            <b-col md="12">
              <b-form-group label="Privacy Policy En" label-for="policyEn">
                <validation-provider
                  #default="{ errors }"
                  name="Privacy Policy En"
                  rules="required"
                >
                  <quill-editor
                    id="policyEn"
                    :options="editorOptions"
                    v-model="value.policyEn"
                    :state="errors.length > 0 ? false : null"
                    :class="
                      errors.length > 0 ? 'border-danger-for-text-editor' : ''
                    "
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Privacy Policy Fr" label-for="policyFr">
                <validation-provider
                  #default="{ errors }"
                  name="Privacy Policy Fr"
                  rules="required"
                >
                  <quill-editor
                    id="policyFr"
                    :options="editorOptions"
                    v-model="value.policyFr"
                    :state="errors.length > 0 ? false : null"
                    :class="
                      errors.length > 0 ? 'border-danger-for-text-editor' : ''
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          >Save</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    quillEditor,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: ["value", "action"],
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
        },
      },
      loading: false,
      formData: null,
      required,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    submit() {
      this.$refs.privacyInfo.validate().then((success) => {
        if (success) {
          const formData = new FormData();
          formData.append("policyEn", this.value.policyEn);
          formData.append("policyFr", this.value.policyFr);
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>
